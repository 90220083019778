// 404.js file

import * as React from 'react'
import { withPrismicUnpublishedPreview, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/linkResolver'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

import slugify from 'slugify'
// import HomeTemplate from './index'
import PageTemplate from './{PrismicPage.url}'

const NotFoundPage = () => {
  return (
    <Layout currentPage={"/not-found"}>
      <Seo title="Page Not Found" />
      <main
        className={"page-not-found"}
      >
        <div className={"container"} style={{ minHeight: '50vh', textAlign: 'center', paddingTop: '12rem', paddingBottom: '6rem' }}>
          <h1 style={{ marginBottom: '0' }}>404</h1>
          Page Not found :/ <br /> <br />
          <a href="/" className='cta small black'  >Go back</a>
        </div>



      </main>
    </Layout >
  )
}

export default withPrismicUnpublishedPreview(
  NotFoundPage,
  [
    {
      repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
      linkResolver,
      componentResolver: componentResolverFromMap({
        page: PageTemplate,
      }),
    },
  ],
)